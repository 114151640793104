import { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { WixCodeSdkHandlersProviderSym, WixCodeSdkParamsProviderSym } from '@wix/thunderbolt-symbols'
import { paymentsWixCodeSdkHandlers, paymentsWixCodeSdkParamsProvider } from './sdk/paymentsSdkProvider'

export const site: ContainerModuleLoader = (bind) => {
	bind(WixCodeSdkParamsProviderSym).to(paymentsWixCodeSdkParamsProvider)
}

export const page: ContainerModuleLoader = (bind) => {
	bind(WixCodeSdkHandlersProviderSym).to(paymentsWixCodeSdkHandlers)
}

export * from './symbols'
export * from './types'
