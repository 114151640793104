import { withDependencies, named } from '@wix/thunderbolt-ioc'
import { WixCodeSdkParamsProvider, SiteFeatureConfigSymbol, SdkHandlersProvider } from '@wix/thunderbolt-symbols'
import { ITpaModal, TpaModalSymbol } from 'feature-tpa'
import { name } from '../symbols'
import { PaymentsWixCodeSdkFactoryData, PaymentsWixCodeSdkHandlers, PaymentsWixCodeSdkSiteConfig } from '../types'

const PaymentsWixCodeSdkParamsProvider = ({
	cashierCsmEntry,
}: PaymentsWixCodeSdkSiteConfig): WixCodeSdkParamsProvider<PaymentsWixCodeSdkFactoryData> => ({
	[name]: {
		initialState: {
			cashierCsmEntry,
		},
	},
})

const PaymentsWixCodeSdkHandlersFactory = ({
	openModal,
}: ITpaModal): SdkHandlersProvider<PaymentsWixCodeSdkHandlers> => ({
	getSdkHandlers() {
		return {
			openModal,
		}
	},
})

export const paymentsWixCodeSdkParamsProvider = withDependencies(
	[named(SiteFeatureConfigSymbol, name)],
	PaymentsWixCodeSdkParamsProvider
)

export const paymentsWixCodeSdkHandlers = withDependencies([TpaModalSymbol], PaymentsWixCodeSdkHandlersFactory)
